/**
 * Events emitted by dialogs:
 * - 'open' CustomEvent with detail pointing on the element that triggered dialog opening
 * - 'close' standard Event, @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLDialogElement/close_event
 * - 'cancel' standard Event, @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/cancel_event
 *
 * Attributes:
 * - data-cancelable: disable closing dialogs with Esc key button (blocking modal dialogs for rare cases). 'true'|'false'. False by default.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dialog
 * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLDialogElement
 */

/**
 * Creates dialog based on title and fields
 * @param {string} dialogTitle
 * @param {object} dialogFields
 * @returns {HTMLDialogElement} dialog element
 */
function createMinimalDialogWithFields(dialogTitle, dialogFields) {
    const dialog = document.createElement('dialog');
    dialog.id = `minimalDialog-${Date.now()}`;
    dialog.className = 'dialog dialog--small';
    dialog.innerHTML = `
<form method="DIALOG">
    <fieldset class="fieldset">
        <legend class="legend">${dialogTitle}</legend>
    </fieldset>
    <footer>
        <menu>
            <button type="submit">OK</button>
            <button type="reset">Cancel</button>
        </menu>
    </footer>
</form>
    `;

    const fieldset = dialog.querySelector('fieldset');
    Object.keys(dialogFields).forEach((name) => {
        const config = dialogFields[name];

        if (!Array.isArray(config)) {
            return;
        }

        const wrapper = document.createElement('p');
        const element = document.createElement(config[0]);
        const properties = config[1];
        const labelText = properties.label || properties.placeholder || '';

        if (labelText && labelText === properties.placeholder) {
            delete properties.placeholder;
        }

        Object.keys(properties).forEach((propertyName) => {
            if (propertyName === 'class') {
                const classes = properties[propertyName].split(' ');
                element.classList.add(classes);
            }
            if (propertyName === 'options') {
                [...properties[propertyName]].forEach((option) => {
                    element.options.add(option.cloneNode(true));
                });
            } else {
                try {
                    element[propertyName] = properties[propertyName];
                } catch (error) {
                    element.setAttribute(propertyName, properties[propertyName]);
                }
            }
        });
        element.name = name;

        if (element.matches('input:not([type="hidden"]') && labelText) {
            const label = document.createElement('label');
            label.insertAdjacentHTML('afterbegin', labelText);
            label.append(element);
            wrapper.append(label);
        } else {
            wrapper.append(element);
        }

        fieldset.append(wrapper);
    });
    return dialog;
}

/**
 * Gets and validates dialog element
 * @param {HTMLDialogElement|string} dialog ID or HTML element
 * @returns {HTMLDialogElement} dialog element
 */
function getValidDialog(dialog) {
    let dialogElement = dialog;
    if (typeof dialog === 'string') {
        dialogElement = document.getElementById(dialog);
    }
    if (!dialogElement) {
        throw new Error(`Dialog with id="${dialogElement?.id || dialog}" doesn't exist`);
    }
    if (!dialogElement.matches('dialog')) {
        throw new Error(`Element with id="${dialogElement?.id || dialog}" is not a dialog`);
    }
    return dialogElement;
}

/**
 * Updates values dynamically for dialog elements that can’t be set in views
 * @param {HTMLDialogElement} dialogElement
 */
function updateA11yAttributes(dialogElement) {
    // If not set yet, update `aria-labelledby` attribute to point on ID of dialog’s title element.
    if (!dialogElement.getAttribute('aria-labelledby')) {
        const titleElement = dialogElement.querySelector('.js-dialogTitle');

        if (titleElement) {
            const titleId = `dialogTitle_${Date.now()}`;

            titleElement.setAttribute('id', titleId);
            dialogElement.setAttribute('aria-labelledby', titleId);
        }
    }
}

/**
 * Close dialog in a safe way with respect of the polyfill
 * @typedef {import('dialog-polyfill/index.d.ts').DialogPolyfillType} DialogPolyfillType
 * @param {DialogPolyfillType} dialogPolyfill
 * @param {HTMLDialogElement} dialog
 */
function closeDialog(dialogPolyfill, dialog) {
    // call function to polyfill <dialog> methods
    dialogPolyfill.registerDialog(dialog);
    // hide dialog and trigger close event
    dialog.close();
}

/**
 * Trigger modal dialog to be displayed.
 * Note, <dialog open> displays NON-MODAL dialog without backdrop.
 * That's why we need to emulate user click to display MODAL dialog.
 * @param {HTMLDialogElement} dialog
 */
function showDialog(dialog) {
    // We create temporary element with data-dialog attribute to trigger
    // dialog init listener that could be declared in a different module
    const fakeElement = document.createElement('a');
    fakeElement.dataset.dialog = dialog.id;
    document.body.append(fakeElement);
    fakeElement.click();
    fakeElement.remove();
}

/**
 * @param {DialogPolyfillType} dialogPolyfill
 */
export function setupDialogSupport(dialogPolyfill) {
    // capture mouse click on document, detect clicked controls
    document.addEventListener('click', (event) => {
        const target = event.target;
        const parentDialog = target.closest('dialog');
        const activationElement = target.closest('[data-dialog]');
        if (activationElement) {

            // if another dialog is open, firstly close it
            if (parentDialog) {
                closeDialog(dialogPolyfill, parentDialog);
            }
            event.preventDefault();
            // open dialog
            const dialogElement = getValidDialog(activationElement.dataset.dialog);
            // call function to polyfill <dialog> methods
            dialogPolyfill.registerDialog(dialogElement);
            // trigger additional open event
            const openEvent = new CustomEvent('open', {detail: activationElement, cancelable: true});
            // do not show dialog if default behavior is prevented
            if (dialogElement.dispatchEvent(openEvent)) {
                // setup extra functionality
                updateA11yAttributes(dialogElement);
                // show dialog immediately
                dialogElement.showModal();
            }
            document.body.classList.add('overflow-hidden');
            dialogElement.addEventListener('close', () => {
                document.body.classList.remove('overflow-hidden');
            }, {once: true});
        } else if (parentDialog && (target.closest(':link') || target.closest('[type="reset"]'))) {
            closeDialog(dialogPolyfill, parentDialog);
        }
    });
    // disable Esc key on dialogs without X button
    document.addEventListener('cancel', (event) => {
        const dialogElement = event.target;
        if (dialogElement.dataset.cancelable !== 'true') {
            event.preventDefault();
        }
    }, true);
    // <dialog open> displays NON-MODAL element without backdrop
    // below we undo this behavior and show modal dialogs instead
    document.querySelectorAll('dialog[open]').forEach((dialogElement) => {
        dialogElement.removeAttribute('open');
        showDialog(dialogElement);
    });
}

/**
 * Displays modal dialog and returns result
 * @param {HTMLDialogElement|string} dialog ID or HTML element
 * @returns {Promise<string>} clicked dialog action
 */
export async function requestDialog(dialog) {
    return new Promise((resolve) => {
        const dialogElement = getValidDialog(dialog);

        dialogElement.addEventListener('close', () => {
            resolve(dialogElement.returnValue);
        }, {once: true});

        showDialog(dialogElement);
    });
}

/** @returns {string} */
function closeDialogButtonInnerHtml() {
    return `
        <button type="reset" aria-label="Close dialog" class="dialog__buttonClose">
            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="svg-icon" width="100%" height="100%">
                <use xlink:href="#x"></use>
            </svg>
        </button>`;
}

/**
 * @todo this is an experimental API that can be unused. If you found it unused in 2025, please remove it.
 * @param {string} innerHtml HTML content of the dialog (as a rule, a <form> element with a content inside)
 * @param {object=} attributes HTML attributes of the dialog.
 * @param {boolean=} withCloseButton Whether to add a close button to the dialog
 * @returns {HTMLDialogElement} HTMLDialogElement detached from the document without any event listeners
 */
function createDynamicDialogElementUsingInnerHtml(innerHtml, attributes, withCloseButton) {
    const dialog = document.createElement('dialog');
    dialog.id = attributes.id || `dynamicDialog-${Date.now()}`;
    dialog.className = attributes.class || 'dialog';

    if (withCloseButton) {
        dialog.innerHTML += closeDialogButtonInnerHtml();
    }

    dialog.innerHTML += innerHtml;

    return dialog;
}

/**
 * Create dialog element and add it to the document with required event listeners.
 * @param {string} innerHtml HTML content of the dialog (as a rule, a <form> element with a content inside)
 * @param {object=} attributes HTML attributes of the dialog.
 * @param {boolean=} withCloseButton Whether to add a close button to the dialog
 * @returns {HTMLDialogElement} HTMLDialogElement detached from the document without any event listeners
 */
export function createDynamicDialog(innerHtml, attributes = {}, withCloseButton = true) {
    const dialog = createDynamicDialogElementUsingInnerHtml(innerHtml, attributes, withCloseButton);

    // add created element to the current document
    document.body.append(dialog);

    // remove dialog when it's closed (because it's dynamic)
    dialog.addEventListener('close', () => {
        dialog.remove();
    });

    return dialog;
}

/**
 * Creates and displays <dialog> element based on title and fields
 * @param {string} title dialog title
 * @param {object} fields dialog fields
 * @returns {Promise<object>} resolved form data
 */
export async function createAndOpenMinimalDialog(title, fields) {
    return new Promise((callback) => {
        const dialogElement = createMinimalDialogWithFields(title, fields);
        // add created element to the current document
        document.body.append(dialogElement);
        // show dialog
        showDialog(dialogElement);
        // submit event triggered when validation passed
        dialogElement.addEventListener('submit', (event) => {
            const data = {};
            new FormData(event.target).forEach((value, key) => {
                data[key] = value;
            });
            callback(data);
        });
        dialogElement.addEventListener('reset', () => {
            callback();
        });
        dialogElement.addEventListener('close', () => {
            dialogElement.remove();
        });
    });
}
